import React from "react";
import Layout from "../../components/layout";
import CapabilitiesHeroSection from "../../components/capabilitiesherosection";
import AuditButtons from "../../components/auditbuttons";
import SingleButton from "../../components/singlebutton";
import { Helmet } from "react-helmet";
import Audits from "../../components/audits";
import Arrow from "../../components/arrow";

import capabilityicon from "../../images/capability-web-and-app-design-3.png";

import webdesign from "../../images/community-management.png";
import webdevelopment from "../../images/content-creation.png";
import iphoneandandroidappdevelopment from "../../images/iphone-and-android-app-development.png";

import rowtwoheroleft from "../../images/web-app-design-row-two-hero-left.jpg";
import rowtwoheroright from "../../images/web-app-design-row-two-hero-right.jpg";

import rowfivehero from "../../images/web-app-design-row-five-hero.png";

import rowninehero from "../../images/web-app-design-row-nine-hero.jpg";

import rowtenhero1 from "../../images/web-app-design-row-ten-hero-1.jpg";
import rowtenhero2 from "../../images/web-app-design-row-ten-hero-2.jpg";
import rowtenhero3 from "../../images/web-app-design-row-ten-hero-3.jpg";
import rowtenhero4 from "../../images/web-app-design-row-ten-hero-4.jpg";

import "../../scss/capabilitypage.scss";

const WebAppDesign = ({ data }) => {
const metadescription = "Next&Co's web and app design services provide digital experiences held accountable to performance visit our site or call 1300 191 950";
const metatitle = "Web Design Agency Melbourne, App Design Company - Next&Co";
const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription}/>
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Arrow/>
      <div className="capability-page-general web-app-design-page">
        <Layout>
          <CapabilitiesHeroSection
            heading="Web &amp; App Design"
            description="Web &amp; App Design – Digital experiences held accountable to performance"
            icon={capabilityicon}
            imagedesktop={data.desktop.childImageSharp.fluid}
            imagemobile={data.mobile.childImageSharp.fluid}
          />
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <h4 className="col-onef-heading">What we do</h4>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={webdesign}/>
                        </span>
                        <div className="content">
                          <h3>Web design</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={webdevelopment} />
                        </span>
                        <div className="content">
                          <h3>Web development</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={iphoneandandroidappdevelopment} style={{ maxWidth: '30px' }} />
                        </span>
                        <div className="content">
                          <h3>iPhone &amp; Android app <br/> development</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your project."
            linktitle1="Get in touch"
            link1="#"
          />
          <section id="row-two">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="row">
                  <div className="col">
                    <img alt="" src={rowtwoheroleft} />
                  </div>
                  <div className="col">
                    <img alt="" src={rowtwoheroright} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-five">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <p>We believe that the right solution lives in the centre of customer needs, business outcomes and insights derived from data. Our approach provides a complete turnkey solution incorporating all the required creative and technology elements ensuring a solution that will achieve the desired business outcomes.</p>
                  <div className="row">
                    <div className="col">
                      <img alt="" src={rowfivehero} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-nine">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="col">
                  <div className="col">
                    <h4>Our Methodology</h4>
                    <p>Next&amp;Co’s Measurably Better™ Web Design Methodology has been defined over half a decade with a collective experience in web development spanning over almost three decades. Leveraging our collective experience, we have refined our methodology to allow us to best understand business objectives and how to marry them with customer needs, delivering truly customer centric digital experiences. </p>
                  </div>
                  <div className="row">
                    <img alt="" src={rowninehero} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-ten">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="small-inner-container">
                    <div className="col">
                      <h4>Our Measurably Better™ Web Design Methodology consists of 4 key phases.</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img alt="" src={rowtenhero1} />
                      <h5>Research &amp; Analysis</h5>
                      <p>The Research &amp; Analysis Phase is designed to discover customer needs and define business objectives</p>
                    </div>
                    <div className="col">
                      <img alt="" src={rowtenhero2} />
                      <h5>Content &amp; <br/> Design</h5>
                      <p>Informed by the Research &amp; Analysis Phase the Content &amp; Design Phase will determine the look and feel of the website</p>
                    </div>
                    <div className="col">
                      <img alt="" src={rowtenhero3} />
                      <h5>Development &amp; Delivery</h5>
                      <p>Building the finalised experience onto the selected platform</p>
                    </div>
                    <div className="col">
                      <img alt="" src={rowtenhero4} />
                      <h5>Support &amp; Optimisation</h5>
                      <p>Once the solution is deployed, the Next&amp;Co Digital team will work on continuous improvement of the digital experience</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-four">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h3>Many of Australia's leading businesses partner with us, you should too.</h3>
                    <SingleButton buttonText="Get in touch"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Audits />
        </Layout>
      </div>
    </>
  );
};

export default WebAppDesign;

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
