import React from "react"

import "../scss/arrow.scss";

class Arrow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: typeof window !== 'undefined' && window.pageYOffset,
      visible: true
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };


  render() {
    return (
      <div className={`scroll-arrow-container ${ !this.state.visible}`}>
        <div className="arrow-container bounce">
          <div className="chevron-container">
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Arrow
