import React from "react"

import "../scss/contactForm.scss"

class ContactForm extends React.Component {
  render() {
    let contactPopup = (
      <section
        id="contact-form-container"
      >
        <div className="outer-container">
          <div className="container-max-width">
            <button
              className="contact-close"
              data-target="navMenu"
              label="Toggle Navigation"
              onClick={this.props.onClose}
            >
              <span className="left" />
              <span className="right" />
            </button>
            <div className="inner-container">
              <div className="col">
                <div className="form-title">
                  <h2>Get in touch</h2>
                </div>
                <form
                  name="contact"
                  method="post"
                  action="/thankyou"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <p hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </p>
                  <p className="field">
                    <input
                      type="text"
                      required
                      name="name"
                      id="name"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    <label>
                      Name
                    </label>
                  </p>
                  <p className="field">
                    <input
                      type="email"
                      required
                      name="email"
                      id="email"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    <label>
                      Email
                    </label>
                  </p>
                  <p className="field">
                    <input
                      type="phone"
                      required
                      name="phone"
                      id="phone"
                      placeholder=""
                      onChange={this.handleChange}
                    />
                    <label>
                      Phone
                    </label>
                  </p>
                  <p className="field">
                    <textarea
                      name="message"
                      id="message"
                      placeholder=""
                      required
                      onChange={this.handleChange}
                    />
                    <label>
                      Message
                    </label>
                  </p>
                  <p>
                    <button type="submit">Send</button>
                  </p>
                </form>
              </div>
              <div className="col">
                <h4 className="title">Our office</h4>
                <p>
                  <a target="_blank" rel="noopener noreferrer" className="address" href="https://www.google.com/maps/place/Level+7%2F22+Albert+Rd,+South+Melbourne+VIC+3205/@-37.8331896,144.9691753,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad642a81209c7c9:0xe05ea617da3ea273!8m2!3d-37.8331939!4d144.971364">Level 7/22 Albert Rd, South Melbourne VIC 3205</a>
                </p>
                <p>
                  <a className="telephone"href="tel:1300191950">1300 191 950</a>
                </p>
                <p>
                  <a className="email"href="mailto:enquiries@nextandco.com.au?subject=Next and Co Enquiry">enquiries@nextandco.com.au</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
    if (! this.props.isOpen) {
      contactPopup = null;
    }
    return (
      <>
        {contactPopup}
      </>
    )
  }
}

export default ContactForm

