import React from "react"
import { Link } from "gatsby"
import "../scss/audits.scss"

class Audits extends React.Component {
  render() {
    return (
      <section id="our-audits" className="section audits-section">
        <div className="outer-container">
          <div className="container-max-width">
            <div className="inner-container">
              <h5 className="section-header">Our audits</h5>
              <div className="column-container">
                <div className="column">
                  <Link
                    to="/digital-media-audit"
                    className="audit-link"
                    title="">
                    Digital Media <span>Audit</span>
                  </Link>
                  {/* <p>Audit me, bruz</p> */}
                  <div className="button-container">
                    <Link
                      to="/digital-media-audit"
                      className="btn"
                    >
                      View audit
                    </Link>
                  </div>
                </div>
                <div className="column">
                  <Link
                    to="/organic-search-audit"
                    className="audit-link"
                    title="audit-link">
                    Search Engine Optimisation <span>Audit</span>
                  </Link>
                  {/* <p>Page one, or die trying</p> */}
                  <div className="button-container">
                    <Link
                      to="/organic-search-audit"
                      className="btn"
                    >
                      View audit
                    </Link>
                  </div>
                </div>
                <div className="column">
                  <Link
                    to="/conversion-rate-optimisation-audit"
                    className="audit-link"
                    title=""
                  >
                    Conversion Rate Optimisation <span>Audit</span>
                  </Link>
                  {/* <p>Get more bang for your buck</p> */}
                  <div className="button-container">
                    <Link
                      to="/conversion-rate-optimisation-audit"
                      className="btn"
                    >
                      View audit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Audits
