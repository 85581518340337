import React from "react"
import { Link } from "gatsby"

import "../scss/buttons.scss"

class SingleButton extends React.Component {
       render() {
        return (
            <>
              <Link
                className="btn btn-invert"
                to="/contact">
                {this.props.buttonText}
              </Link>
            </>
        )
    }
}

export default SingleButton
