import React from "react";
import { Link } from "gatsby";
import ContactForm from "../components/contactform"

import "../scss/auditbuttons.scss";

class AuditButtons extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            isOpen: false
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <section className="section audit-buttons-section">
              <div className="outer-container">
                <div className="container-max-width">
                  <div className="inner-container">
                    {/* <div className="small-inner-container"> */}
                    <div className="col col-audit col-top">
                      <span className="line"/>
                      <p>{this.props.title1}</p>

                      <Link
                        className="btn btn-invert"
                        to="/contact">
		        {this.props.linktitle1}
	              </Link>                      
                    </div>
                    { this.props.title2 || this.props.link2 || this.props.linktitle2 ?
                      <div className="col col-audit col-bottom">
                        <span className="line"/>
                        <p>{this.props.title2}</p>
                        <Link
                          to={this.props.link2}
                          className="btn btn-invert"
                          title={this.props.linktitle2}
                        >
                          {this.props.linktitle2}
                        </Link>
                      </div>
                      : null }
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </section>
        )
    }
}

export default AuditButtons
